export const multiplyScalar = 0.2;

export const headerMenus = [
  {
    title: "Overview",
  },
  {
    title: "Electric Capability",
  },
  {
    title: "Range and Charging",
  },
  {
    title: "Reserve Now",
  },
  {
    title: "Productivity",
  },
  {
    title: "Towing and Hauling",
  },
  {
    title: "Model Lineup",
  },
];

export const trims = [
  {
    id: "500A",
    value: "500A",
    title: "LARIAT 500A",
    blockedExtColors: [],
  },
  {
    id: "300A",
    value: "300A",
    title: "XLT 300A",
    blockedExtColors: [],
  },
  {
    id: "100A",
    value: "100A",
    title: "XL 100A",
    blockedExtColors: ["L3", "EA", "SB"],
  },
];

export const exteriorColors = [
  {
    id: "L3",
    name: "Alto Blue",
    color: "#4b6890",
    colorCheck: "#fff",
  },
  {
    id: "KU",
    name: "Area 51",
    color: "#799bbe",
    colorCheck: "#fff",
  },
  {
    id: "NE",
    name: "Cactus Grey",
    color: "#cfd1d1",
    colorCheck: "#fff",
  },

  {
    id: "M7",
    name: "Carbonized Gray Metallic",
    color: "#5b5f65",
    colorCheck: "#fff",
  },
  {
    id: "SB",
    name: "Cyber Orange",
    color: "#efcd93",
    colorCheck: "#fff",
  },
  {
    id: "EA",
    name: "Hot Pepper Red ",
    color: "#d06965",
    colorCheck: "#fff",
  },

  {
    id: "JS",
    name: "Iconic Silver Metallic ",
    color: "#e9e8e5",
    colorCheck: "#fff",
  },

  {
    id: "YZ",
    name: "Oxford White ",
    color: "#f2f2f0",
    colorCheck: "#000",
  },
  {
    id: "G1",
    name: "Shadow Black ",
    color: "#0d0e0b",
    colorCheck: "#fff",
  },
  {
    id: "E7",
    name: "Velocity Blue",
    color: "#50a4d6",
    colorCheck: "#fff",
  },
];

export const interiorColors = [
  {
    id: "IntColorCode_01",
    name: "Black Onyx",
    color: "#3d4248",
    colorCheck: "#fff",
    trim: "100A",
  },
  {
    id: "IntColorCode_02",
    name: "Navy Pier",
    color: "#003859",
    colorCheck: "#fff",
    trim: "300A",
  },
  {
    id: "IntColorCode_03",
    name: "Desert Brown",
    color: "#554139",
    colorCheck: "#fff",
    trim: "500A",
  },
];

export const cameraControls = [
  {
    value: 1,
    title: "Exterior-1"
  },
  {
    value: 2,
    title: "Exterior-2"
  },
  {
    value: 3,
    title: "Exterior-3"
  },
  {
    value: 4,
    title: "Exterior-4"
  },
  {
    value: 5,
    title: "Exterior-5"
  },
  {
    value: 6,
    title: "Interior-1"
  },
  {
    value: 7,
    title: "Interior-2"
  },
  {
    value: 8,
    title: "Panorama"
  }
]