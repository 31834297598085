import React from "react";
import jwt_decode from "jwt-decode";
import { AuthProvider, AuthService, useAuth } from "react-oauth2-pkce";

const authService = new AuthService({
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  provider: process.env.REACT_APP_AUTH_PROVIDER,
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  scopes: ["openid", "email"],
});

function SecuredApp(pageProps) {
  // const { authService } = useAuth();

  // const login = async () => authService.authorize();
  // const logout = async () => authService.logout();

  //   let data = authService.getAuthTokens();
  //   console.log("dataToken", data);

  //   if (authService.isPending()) {
  //     return (
  //       <div>
  //         Loading...
  //         <button
  //           onClick={() => {
  //             logout();
  //             login();
  //           }}
  //         >
  //           Reset
  //         </button>
  //       </div>
  //     );
  //   }

  // if (!authService.isAuthenticated()) {
  //   return (
  //     <div className="login">
  //       <div className="login_form">
  //         <div className="login_form_buttons">
  //           <Button
  //             text="Login"
  //             className="btnFormSubmitLogin"
  //             onClick={login}
  //             icon={<ExitToAppIcon />}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  //   let data = authService.getAuthTokens();
  //   console.log("dataToken", data);

  //   if (data.error != undefined) {
  //     console.log(data.error);
  //     // login()
  //     return <div>Auth Error...</div>;
  //   }

  //   const jwtPayload = jwt_decode(data.id_token);
  //   const token = data.access_token;

  return (
    <>
      {pageProps.children}
    </>
  );
}


function WrappedSecuredApp(pageProps) {
  if (typeof window === "undefined") return;
  return (
    <AuthProvider authService={authService}>
      <SecuredApp {...pageProps} />
    </AuthProvider>
  );
}

export default WrappedSecuredApp;
