
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';

// png
import threeDotImg from '../../assets/png/three-dots.png';
import logoImg from '../../assets/png/ford-logo.png';
import cameraImg from '../../assets/png/camera.png';

// JPG
import modePanoImg from '../../assets/jpg/mode-pano.jpeg';
import modeExteriorImg from '../../assets/jpg/mode-exterior.jpeg';
import modeInteriorImg from '../../assets/jpg/mode-interior.jpeg';

// Icons
export const LogoIcon = Logo;

// Components
export const ThreeDotImg = () => <img src={threeDotImg}/>;
export const LogoImg = () => <img src={logoImg}/>;
export const CameraImg = () => <img src={cameraImg}/>;

export const ModePanoImg = () => <img src={modePanoImg}/>;
export const ModeExteriorImg = () => <img src={modeExteriorImg}/>;
export const ModeInteriorImg = () => <img src={modeInteriorImg}/>;