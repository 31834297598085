import React from "react";
import { Redirect } from "react-router-dom";
// import Header from "../layout/Header";
// import Footer from "../layout/Footer";

const PublicRoute = (props) => {
  if (props.isAuthenticated) return <Redirect to="/dashboard" />;
  return (
    <>
      {/* <Header {...props} /> */}
      <div className="public">
        {/* <h1>Public</h1> */}
        {props.children}
        </div>
      {/* <Footer /> */}
    </>
  );
};

export default PublicRoute;