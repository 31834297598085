import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Header from "../components/Header/Header";


const PrivateRoute = (props) => {
  if (props.isAuthenticated == false) return <Redirect to="/login" />;
  return (
    <>
      <Header />
      <div className="private">
          {props.children}
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default PrivateRoute;
