import React from "react";
import clsx from "clsx";
import "./Icon.scss";
const Icon = (props) => {
  const { item, onClick, active, className, ...input } = props;
  return (
    <span
      className={
        clsx("commonIcon", className ? className : "", active ? "active" : "")
      }
      onClick={onClick ? onClick : undefined}
      {...input}
    >
      {item ? item : ""}
      {props.children}
    </span>
  );
};

export default Icon;
